import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closePopup, createStaff, updateStaff } from '../../store/staffSlice';
import Icon from "../common/Icon";
import { getPublishers } from '../../store/publisherSlice';
import { 
    validateEmail,
    validateName,
    validateWebPassword,
    validatePasswordConfirm,
} from "../common/Validation";
import { t } from "i18next";

const StaffPopup = () => {

    let loading = useSelector(state => state.staff.loading);
    let popup = useSelector(state => state.staff.popup);
    let form = useSelector(state => state.staff.form);
    let listPublisher = useSelector(state => state.publisher.list);

    const initialState = {
        name: '',
        email: '',
        password: '',
        passwordConfirmation: '',
        type: 'SubAdmin',
        publisherId: '',
        ...form
    };

    let [{ name, email, password, passwordConfirmation, publisherId, type }, setState] = useState(initialState);
    let [ nameAlert, setUserAlert ] = useState();
    let [ emailAlert, setEmailAlert ] = useState();
    let [ passAlert, setPassAlert ] = useState();
    let [ passConfirmAlert, setPassConfirmAlert ] = useState();

    useEffect(() => {
        setState(initialState)
        dispatch(getPublishers());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form]);

    const dispatch = useDispatch();

    const close = (e) => {
        e.preventDefault();
        form = null;
        dispatch(closePopup());
    };


    const validation = (e) => {
        if (validateName(name)) {
            setUserAlert(t('staff.popup.nameAlert')); 
            return false;
        } else {
            setUserAlert("");
        }
    
        if (validateEmail(email)) {            
            setEmailAlert(t('staff.popup.emailAlert')); 
            return false;
        } else {
            setEmailAlert("");
        }
        
        return true;
    }

    const create = (e) => {
        e.preventDefault();
        if (!validation()){
            return;
        }

        if (validateName(name)) {
            setUserAlert(t('staff.popup.nameAlert')); 
            return false;
        } else {
            setUserAlert("");
        }
        
        dispatch(createStaff({
            name: name,
            email: email,
            // password: password,
            publisherId: publisherId,
            type: type,
            responseAlert: t('staff.popup.createSuccess')
        }));
    }

    const update = (e) => {
        e.preventDefault();
        
        dispatch(updateStaff({
            id: form.id,
            name: name,
            email: email,
            // password: password,
            responseAlert: t('staff.popup.updateSuccess')
        }));
    }

    const change = (e) => {
        let { name, value } = e.target;
        setState(prevState => ({ ...prevState, [name]: value }))
        
        if (name === "name" && validateName(value)) {
            setUserAlert(t('staff.popup.nameAlert')); 
            return false;
        } else if (name === "name") {
            setUserAlert("");
        }
    
        if (name === "email" && validateEmail(value)) {            
            setEmailAlert(t('staff.popup.emailAlert')); 
            return false;
        } else if (name === "email") {
            setEmailAlert("");
        }
    }

    const renderSelectOptionPublishers = (data) => {
        if (data.map) {
            return data.map((key) => {
                return (
                    <option value={key.id}>{key.name}</option>
                )
            });
        }
    }

    const renderForm = () => {
        return (
            <form >
                <div className="form-group">
                    <label>{ t('staff.email') }</label>
                    <input type="text" className="form-control" value={email} name="email" readOnly={form} onChange={change} />
                    <small className={"form-text text-danger font-italic " + (emailAlert ? "" : "hidden")}>{emailAlert}</small>
                </div>

                <div className="form-group">
                    <label>{ t('staff.name') }</label>
                    <input type="text" className="form-control" value={name} name="name" onChange={change} />
                    <small className={"form-text text-danger font-italic " + (nameAlert ? "" : "hidden")}>{nameAlert}</small>
                </div>

                { form ?
                <div></div>
                :
                <div className="form-group">
                    <label>{ t('staff.popup.publisher') }</label>
                    <select name="publisherId" className="form-control" value={publisherId} onChange={change}>
                        <option selected value=''>{ t('staff.popup.selectPublisher') }</option>
                        { renderSelectOptionPublishers(listPublisher) }
                    </select>
                </div>
                }

                { form ?
                <div></div>
                :
                <div className="form-group">
                    <label>{ t('staff.role') }</label>
                    <select name="type" className="form-control" value={type} onChange={change}>
                        <option selected value='SubAdmin'>{ t('shared.subAdmin') }</option>
                        <option value='Admin'>{ t('shared.admin') }</option>
                    </select>
                </div>
                }

                {/* <div className="form-group">
                    <label>{ t('staff.password') }</label>
                    <input type="password" value={password} className="form-control" name="password" onChange={change} />
                    <small className={"form-text text-danger font-italic " + (passAlert ? "" : "hidden")}>{passAlert}</small>
                </div>
        
                <div className="form-group">
                    <label>{ t('staff.passConfirm') }</label>
                    <input type="password" value={passwordConfirmation} className="form-control" name="passwordConfirmation" onChange={change} />
                    <small className={"form-text text-danger font-italic " + (passConfirmAlert ? "" : "hidden")}>{passConfirmAlert}</small>
                </div> */}
 
            </form>
        );
    }

    return (
        <div className={"popup" + (popup ? " popup-show" : "")}>
            <div className="popup-overlay"></div>

            <div className="popup-wrapper">
                <div className="popup-content">
                    <div className="card">
                        <div className="card-header">
                            {form ?
                                <h5 className="card-title">{ t('staff.popup.editStaff') }</h5>
                                :
                                <h5 className="card-title">{ t('staff.popup.newStaff') }</h5>
                            }
                            <div>
                                <a href="/#" onClick={close}>
                                    <Icon icon="x" />
                                </a>

                            </div>
                        </div>

                        <div className="card-body">

                            { renderForm() }    
                        
                            <div className="actions">
                                {form ?
                                    <button onClick={update} className="btn btn-primary">{ t('shared.update') }</button>
                                    :
                                    <button onClick={create} className="btn btn-primary">{ t('shared.create') }</button>
                                }
                                <div className="fill"></div>

                                {loading &&
                                    <div className="loading">
                                        <div className="threedots">
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StaffPopup;