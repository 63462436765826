import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closePopup, createAdmin, updateAdmin } from '../../store/adminSlice';
import Icon from "../common/Icon";
import {
  validateEmail,
  validateName,
  validatePassword,
  convertBlankInput,
} from "../common/Validation";
import { t } from 'i18next';

const AdminPopup = () => {

  let loading = useSelector(state => state.admin.loading);
  let popup = useSelector(state => state.admin.popup);
  let form = useSelector(state => state.admin.form);

  const initialState =  {
    name: '',
    email: '',
    password: '',
    type: 'SubAdmin',
    passwordConfirmation: '',
    ...form
  };

  let [{ name, email, type, password, passwordConfirmation }, setState] = useState(initialState);
  let [nameAlert, setUserAlert] = useState();
  let [emailAlert, setEmailAlert] = useState();
  let [passAlert, setPassAlert] = useState();
  let [passConfirmAlert, setPassConfirmAlert] = useState();

  useEffect(() => { 
    setState(initialState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form] );

  const dispatch = useDispatch();

  const close = (e) => {
    e.preventDefault();
    dispatch(closePopup());
  };

  const validation = (e) => {

    if (validateName(name)) {
      setUserAlert(t('admin.popup.userAlert'));
      return false;
    } else {
      setUserAlert("");
    }

    if (validateEmail(email)) {
      setEmailAlert("admin.popup.emailAlert");
      return false;
    } else {
      setEmailAlert("");
    }

    return true;
  }

  const create = (e) => {
    e.preventDefault();

    if (!validation()) {
      return;
    }

    dispatch(createAdmin({
      name: name,
      email: email,
      // password: password,
      type: type,
      responseAlert: t('admin.popup.createSuccess')
    }));
  }

  const update = (e) => {
    e.preventDefault();

    if (validateName(name)) {
      setUserAlert(t('admin.popup.nameAlert'));
      return false;
    } else {
      setUserAlert("");
    }

    if (validateEmail(email)) {
      setEmailAlert(t('admin.popup.emailAlert'));
      return false;
    } else {
      setEmailAlert("");
    }

    dispatch(updateAdmin({
      id: form.id,
      name: name,
      email: email,
      type: type,
      // password: convertBlankInput(password),
      responseAlert: t('admin.popup.updateSuccess')
    }));
  }

  const change = (e) => {
    let { name, value } = e.target;
    setState(prevState => ({ ...prevState, [name]: value }));
    if (name === "name" && validateName(value)) {
      setUserAlert(t('admin.popup.nameAlert'));
      return false;
    } else if (name === "name") {
      setUserAlert("");
    }

    if (name === "email" && validateEmail(value)) {
      setEmailAlert(t('admin.popup.emailAlert'));
      return false;
    } else if (name === "email") {
      setEmailAlert("");
    }

    
  }

  return (
    <div className={"popup" + (popup ? " popup-show" : "")}>
      <div className="popup-overlay"></div>

      <div className="popup-wrapper">
        <div className="popup-content">
          <div className="card">
            <div className="card-header">
              {form ?
                <h5 className="card-title">{ t('admin.popup.editAdmin') }</h5>
                :
                <h5 className="card-title">{ t('admin.popup.newAdmin') }</h5>
              }
              <div>
                <a href="/#" onClick={close}>
                  <Icon icon="x" />
                </a>

              </div>
            </div>

            <div className="card-body">
              <form >
                <div className="form-group">
                  <label>{ t('admin.popup.labEmail') }</label>
                  <input type="email" className="form-control" value={email} name="email" readOnly={form} onChange={change} />
                  <small className={"form-text text-danger font-italic " + (emailAlert ? "" : "hidden")}>{emailAlert}</small>
                </div>

                {form ?
                  <div></div>
                  :
                  <div className="form-group">
                    <label>{ t('admin.popup.labRole') }</label>
                    <select name="type" className="form-control" value={type} onChange={change}>
                      <option value='SubAdmin'>{ t('admin.popup.subAdmin') }</option>
                      <option value='Admin'>{ t('admin.popup.admin') }</option>
                    </select>
                  </div>
                }

                <div className="form-group">
                  <label>{ t('admin.popup.labName') }</label>
                  <input type="text" className="form-control" value={name} name="name" onChange={change} />
                  <small className={"form-text text-danger font-italic " + (nameAlert ? "" : "hidden")}>{nameAlert}</small>
                </div>

                {/* <div className="form-group">
                  <label>{ t('admin.popup.labPass') }</label>
                  <input type="password" className="form-control" value={password} name="password" onChange={change} />
                  <small className={"form-text text-danger font-italic " + (passAlert ? "" : "hidden")}>{passAlert}</small>
                </div>
                <div className="form-group">
                  <label>{ t('admin.popup.labPassConfirm') }</label>
                  <input type="password" className="form-control" value={passwordConfirmation} name="passwordConfirmation" onChange={change} />
                  <small className={"form-text text-danger font-italic " + (passConfirmAlert ? "" : "hidden")}>{passConfirmAlert}</small>
                </div> */}
              </form>

              <div className="actions">
                {form ?
                  <button onClick={update} className="btn btn-primary">{ t('admin.popup.update') }</button>
                  :
                  <button onClick={create} className="btn btn-primary">{ t('admin.popup.create') }</button>
                }
                <div className="fill"></div>

                {loading &&
                  <div className="loading">
                    <div className="threedots">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminPopup;