import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { login } from '../../store/authSlice';
import { t } from 'i18next';

export default function AdminLogin() {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    let history = useHistory();

    const goToAdmin = () => {
        history.push('/admins');
    }

    const goToPublisher = () => {
        history.push('/publishers');
    }

    useSelector(state => {
        let accessToken = state.auth.accessToken;
        let type = state.auth.type;
        if (accessToken != null) {
            if (type === 'SuperAdmin') {
                goToAdmin();
            } else {
                goToPublisher();
            }
            return ;
        }
    });

    let loading = useSelector(state => state.auth.loading);

    const dispatch = useDispatch();

    const onLogin = (e) => {
        e.preventDefault();

        if (email.length > 0 && password.length > 0) {
            dispatch(
                login('admin', email, password)
            );
        }
    }

    return (
        <div className="wrapper-login background-login">
            <div className="card-login">
                <div className="">
                    <h3 className="card-title mb-3">{ t('admin.login.title') }</h3>
                    <form>
                        <div className="form-group">
                            <label>{ t('shared.login.labEmail') }</label>
                            <input type="text" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </div>

                        <div className="form-group">
                            <label>{ t('shared.login.labPass') }</label>
                            <input type="password" className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} />
                        </div>

                        <div className="actions">
                            <button className="btn btn-primary btn-login" onClick={onLogin}>{ t('shared.login.login') }</button>

                            {loading &&
                                <div className="loading">
                                    <div className="threedots">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                </div>
                            }
                        </div>
                        
                    </form>
                    <div className="d-flex justify-content-end">
                        <a className="mt-4" href="/login">Go to Publisher Portal Login</a>
                    </div>
                </div>
            </div>
        </div>
    );
}